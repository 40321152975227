export default class OrderCropItem {
  constructor(data = {}) {
    this.cropId = String(data.cropId || '');
    this.area = data.area === null || Number.isNaN(Number(data.area)) ? null : Number(data.area);
    this.startDate = String(data.startDate || '');
    this.endDate = String(data.endDate || '');
    this.perHectar = data.perHectar === null || Number.isNaN(Number(data.perHectar))
      ? null : Number(data.perHectar);
  }
}
