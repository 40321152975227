<template>
  <div class="d-flex flex-column">
    <section class="wet-section">
      <div class="container-lg">
        <h3 class="empty-ph is-size-6">
          {{ cropSelection.subtitle }}
        </h3>
        <div class="mb-3 position-relative">
          <div
            class="text-justify"
            v-html="cropSelection.subtitle_text"
            v-collapse="{ value: !isMobile || readMore, minHeight: '7rem' }"
          />
          <div v-if="isMobile" class="wet-read-more" :class="{
            opened: readMore,
          }">
            <a href="#" class="text-secondary" @click.prevent="readMore = !readMore">
              {{ readMore ? commonApp.hide : commonApp.read_more }}
            </a>
          </div>
        </div>
        <div class="border-y-between">
          <crop-selection-item
            v-for="item in crops"
            :key="item.id"
            :title="item.title"
            :crop="item.id"
            :image="item.image"
            :accent="!someSelected"
            v-bind="selectedBind[item.id]"
            v-on="selectedOn[item.id]"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import common from '@/mixins/common';
import OrderCropItem from '@/models/order-crop-item';
import wpSection from '@/enums/wpSection';

export default {
  name: 'CropSelection',
  mixins: [common],
  components: {
    cropSelectionItem: () => import(/* webpackChunkName: "cropSelectionItem" */ '@/components/crop-selection/cropSelectionItem.vue'),
  },
  data() {
    return {
      wpApiCropSelection: '/wp-json/acf/v3/options/crop-selection',
      wpDroughtPath: '/wp-json/acf/v3/options/drought', /** for get crop images */
      saveCropSelectionApi: '/api/risk_assessment/crop_selection', /** save selection crops */
      selected: {},
      readMore: false,
    };
  },
  computed: {
    ...mapState(['strings', 'cropSelected', 'isMobile']),
    ...mapState({
      crops(state) {
        return [
          ...state.settings.crops.map((c) => ({
            ...c,
            image: this.images[c.id]?.photo,
            title: this.images[c.id]?.title,
          })),
        ].filter((c) => this.strings
          .drought?.crops?.filter((cc) => cc.id === c.id)[0]?.crop_selection);
      },
    }),
    images() {
      const images = this.strings
        .drought?.crops?.map((c) => [c.id, { photo: c.photo, title: c.title }]);
      return Object.fromEntries(images || []);
    },
    selectedBind() {
      return Object.fromEntries(this.crops?.map((c) => ([
        c.id,
        {
          defaultStartDate: c.default_start,
          defaultEndDate: c.default_end,
          ...new OrderCropItem(this.selected[c.id]),
        },
      ])) || []);
    },
    selectedOn() {
      return Object.fromEntries(this.crops?.map((c) => ([
        c.id,
        {
          'update:area': (v) => this.$set(this.selected, c.id, { ...this.selected[c.id], area: v }),
          'update:startDate': (v) => this.$set(this.selected, c.id, { ...this.selected[c.id], startDate: v }),
          'update:endDate': (v) => this.$set(this.selected, c.id, { ...this.selected[c.id], endDate: v }),
        },
      ])) || []);
    },
    ha() {
      return this.strings.commonApp?.unit.ha;
    },
    cropSelection() {
      return this.strings.cropSelection || {};
    },
    commonApp() {
      return this.strings.commonApp || {};
    },
    nextButton() {
      return this.commonApp.next;
    },
    someSelected() {
      return Object.values(this.selected).some((c) => !!c.area);
    },
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(val) {
        const selected = this.$lodash.pickBy(val, (el) => !!el.area);
        if (!this.$lodash.isEqual(selected, this.cropSelected)) {
          this.$store.commit('SET_CROP_SELECTED', selected);
        }
      },
    },
    cropSelected: {
      handler(selected) {
        this.$emit('update:nextDisabled', Object.keys(selected).length === 0);
      },
    },
    nextButton: {
      immediate: true,
      handler(title) {
        // set next button title
        this.$emit('update:nextTitle', title);
      },
    },
    'cropSelection.area_tip': {
      handler() {
        // this.initIntro();
      },
    },
  },
  async created() {
    await Promise.all([
      this.$emit('update:nextDisabled', true),
      this.$root.$on('buttonNext', this.next),
      this.$root.$on('buttonBack', this.back),
      this.getWordPressStrings(
        [wpSection.DROUGHT, wpSection.CROP_SELECTION],
      ),
      this.getCrops(),
    ]);
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);

    // set next button title
    this.$emit('update:nextTitle', '');
  },
  methods: {
    ...mapMutations(['SET_INTRO']),
    async getCrops() {
      /** get crops */
      await this.$store.dispatch('settings/getProductSettings', { site: 'drought' });
    },
    async saveCropSelection() {
      const data = {
        crops: Object.entries(this.cropSelected).map(([type, c]) => ({
          type,
          hectar: c.area,
          start: c.startDate,
          end: c.endDate,
        })),
      };
      if (data.crops.some((c) => [c.start, c.end].includes('Invalid date'))) {
        return Promise.reject(new Error('Invalid date'));
      }
      return this.callApi({ url: this.saveCropSelectionApi, method: 'POST', data });
    },
    async next() {
      // next button handler
      // check validation
      if (Object.keys(this.cropSelected).length === 0) {
        this.initIntro();
        return;
      }

      // call api to save inputs
      await this.saveCropSelection();

      // go to next page
      this.$router.push({ name: 'crop-location', params: { ...this.$route.params } });
    },
    back() {
      //
    },
    initIntro() {
      const items = [
        {
          title: this.cropSelection.area_tip,
          el: '.crop-selection-item-area-input',
        },
      ];
      const intro = { title: 'intro', items };
      this.SET_INTRO(intro);
    },
  },
};
</script>
<style lang="scss">
  .wet-read-more {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1.5rem 0.5rem 1rem 0.5rem;
    background:
      linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%);
    text-align: center;

    &.opened {
      position: relative;
    }
  }
</style>
